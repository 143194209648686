(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name neo.home.championships.championship.editMatches.controller:EditMatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.championships.championship.editMatches')
    .controller('EditMatchesCtrl', EditMatchesCtrl);

  function EditMatchesCtrl($location, $mdColors, $filter, $mdToast, $state, ChampionshipMatches, championship, categories, sports, genders, championshipCalendar, facilities, towns, teams) {
    var vm = this;
    vm.ctrlName = 'EditMatchesCtrl';

    vm.championship = championship;

    vm.category = $filter('translate')('CATEGORIES.' + findItemById(categories, vm.championship.categoryId).name);

    vm.sport = $filter('translate')('SPORTS.' + findItemById(sports, vm.championship.sportId).name);
    vm.gender = $filter('translate')(findItemById(genders, vm.championship.genderId).name);

    vm.facilities = buildFacilities(championshipCalendar.facilities.concat(facilities), towns);
    vm.matchDays = buildMatchDays(championshipCalendar.matchDays, vm.teams, vm.facilities, championshipCalendar.sportMatchDay);
    vm.teams = buildTeams(_.filter(teams, function (t) {
      return _.contains(t.joinedChampionships, championship.id)
    }));

    vm.getTeamName = getTeamName;
    vm.getFacilityName = getFacilityName;
    vm.saveMatchDays = saveMatchDays;

    vm.addMatch = addMatch;
    vm.addMatchDay = addMatchDay;
    vm.deleteMatch = deleteMatch;
    vm.deleteMatchDay = deleteMatchDay;

    vm.getColor = getColor;

    function addMatch(matchDay) {

      matchDay.matches.push({
        id: null,
        date: moment(matchDay.date).toDate(),
        time: null,
        localTeamId: null,
        visitorTeamId: null,
        facilityId: null
      });
    }

    function addMatchDay(matchDays) {
      var newMatchDay = {
        number: _.isEmpty(matchDays) ? 1 : _.max(matchDays, 'number').number + 1,
        date: moment().toDate(),
        matches: []
      };
      addMatch(newMatchDay);
      matchDays.push(newMatchDay);
      $location.hash('matchday' + (matchDays.length - 1));
    }

    function deleteMatch(matchDayIndex, matchIndex) {
      vm.matchDays[matchDayIndex].matches.splice(matchIndex, 1);
    }

    function deleteMatchDay(matchDays, index) {
      matchDays.splice(index, 1);
    }

    function buildTeams(teams) {
      var newTeams = {};
      angular.forEach(teams, function (team) {
        newTeams[team.teamId] = team;
      });
      return newTeams;
    }

    function buildFacilities(facilities, towns) {
      var newFacilities = {};
      angular.forEach(facilities, function (facility) {
        newFacilities[facility.id] = facility;
        newFacilities[facility.id].town = findItemById(towns, facility.idTown);
      });
      return newFacilities;
    }

    function buildMatchDays(matchDays, sportMatchDay) {
      angular.forEach(matchDays, function (matchDay) {
        matchDay.matches = buildMatches(matchDay.matches);
        matchDay.sportMatchDay = false;
      });
      matchDays = addSportMatchDay(matchDays, sportMatchDay);
      return matchDays;
    }

    function buildMatches(matches) {
      var newMatches = [];
      angular.forEach(matches, function (match) {
        newMatches.push({
          id: match.id,
          date: moment(match.date).toDate(),
          time: _.isNull(match.time) ? null : moment(match.time, 'HH:mm').toDate(),
          localTeamId: match.localTeamId,
          visitorTeamId: match.visitorTeamId,
          facilityId: match.facilityId
        });
      });
      return newMatches;
    }

    function addSportMatchDay(matchDays, sportMatchDay) {
      matchDays = _.sortBy(matchDays, 'date');
      if (_.size(matchDays) && sportMatchDay > _.head(matchDays).date && sportMatchDay < _.last(matchDays).date) {
        matchDays.push({
          sportMatchDay: true,
          date: sportMatchDay
        });
      }
      return _.sortBy(matchDays, 'date');
    }

    function getTeamName(teamId) {
      var team = vm.teams[teamId];
      return angular.isUndefined(team) ? undefined : team.teamInfo.name;
    }

    function getFacilityName(facilityId) {
      var facility = vm.facilities[facilityId];
      return angular.isUndefined(facility) ? undefined : facility.name;
    }


    function getColor(colorName) {
      return $mdColors.getThemeColor(colorName);
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

    function saveMatchDays() {
      var matchDaysToSave = [];
      angular.forEach(vm.matchDays, function (matchDay) {

        var matchesToSave = [];
        angular.forEach(matchDay.matches, function (match) {
          matchesToSave.push({
            id: match.id,
            localTeamId: match.localTeamId,
            visitorTeamId: match.visitorTeamId,
            date: moment(match.date).format('YYYY-MM-DD'),
            time: _.isNull(match.time) ? null : moment(match.time).format('HH:mm'),
            facilityId: match.facilityId
          })
        });

        matchDaysToSave.push({
          number: matchDay.number,
          date: moment(matchDay.date).format('YYYY-MM-DD'),
          matches: matchesToSave
        });
      });

      ChampionshipMatches.replace({id: vm.championship.id}, matchDaysToSave, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('CHAMPIONSHIPS.MATCH_DAYS_SUCCESSFUL_UPDATE'))
            .position('bottom left')
            .hideDelay(3000)
        );
        $state.reload();
      }, function (err) {
        var message = err.data.message || 'ERROR_UPDATE';
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    }

  }
}());
